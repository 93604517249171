<script lang="ts" setup>
import {getHumanReadableDate, formatDateForBackend} from '@/helpers/date'
import {getAddressFromLocation, getLocationFromAddress} from '@/helpers/google'
import {getFiltersFromQuery} from '@/helpers/query'
const props = withDefaults(
  defineProps<{
    immediate?: boolean
    includeType?: boolean
    includeDate?: boolean
    includeAmenities?: boolean
  }>(),
  {
    immediate: false,
    includeType: false,
    includeAmenities: false,
    includeDate: true,
  }
)
const emit = defineEmits<{
  (e: 'submit', data: SearchForm): void
  (e: 'dateChanged', data: SearchForm): void
}>()
const route = useRoute()
const filters = useFilters()
const language = process.server ? useRequestHeaders()['accept-language']?.split(',')[0] : navigator.language

const availableDates = computed<SelectItem[]>(
  () =>
    filters.value?.availableDates.map(date => ({
      label: getHumanReadableDate(new Date(date), language),
      value: formatDateForBackend(new Date(date)),
    })) ?? []
)
console.log(availableDates)
const types = computed(
  () =>
    filters.value?.locationTypes.map(locationType => ({
      label: locationType.name,
      value: locationType.slug,
    })) ?? []
)

const amenities = computed(
  () =>
    filters.value?.amenities.map(amenity => ({
      fa: amenity.icon,
      label: amenity.name,
      value: amenity.slug,
    })) ?? []
)

const form = reactive<Required<SearchForm>>({
  latlng: '',
  address: '',
  date: '',
  location_type: '',
  amenities: [],
})

const handleSubmit = () => {
  const clonedForm: SearchForm = toRaw(form)
  if (!props.includeType) delete clonedForm.location_type
  if (!props.includeDate) delete clonedForm.date
  if (!props.includeAmenities) delete clonedForm.amenities
  emit('submit', clonedForm)
}

const handleDateChange = () => emit('dateChanged', form)

const setDefaultAddress = async ({address, latlng}: {address: string, latlng: string}) => {
  // if latlng is a valid string and address is an empty string
  // We try to fetch address via latlng trough google
  if (latlng && !address) form.address = (await getAddressFromLocation(latlng)) ?? ''
  // else if its a string just set it
  else if (address) form.address = address

  // same goes for address but the other way around
  if (address && !latlng) form.latlng = (await getLocationFromAddress(address)) ?? ''
  else if (latlng) form.latlng = latlng
}

const datesAreOnSameDay = (first: Date, second: Date) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

// Returns the second date when its already halfway trough the day and the first date is today,
// Since the probabability that the user wants to book for tomorrow is higher.
const getDefaultDate = () => {
  return availableDates.value.at(0)?.value ?? ''
}

const setDefaultDate = (date: string) => {
  const selectedDate = availableDates.value.find((d) => d.value === date)?.value ?? getDefaultDate()
  form.date = selectedDate
  handleDateChange()
}

const setDefaultType = (location_type: string) => {
  form.location_type = location_type
}

const setDefaultAmenities = (amenities: string[]) => {
  form.amenities = amenities
}

const setDefaults = async () => {
  const { address, latlng, date, location_type, amenities } = getFiltersFromQuery({query: route.query})
  if (props.includeDate) setDefaultDate(date)
  if (props.includeType) setDefaultType(location_type)
  if (props.includeAmenities) setDefaultAmenities(amenities)
  await setDefaultAddress({ address, latlng  })
  if (props.immediate) handleSubmit()
}

onMounted(async () => {
  setDefaults()
})

setDefaults()
</script>

<template>
  <form @submit.prevent="handleSubmit">
    <LocationsInput v-model:address="form.address" v-model:latlng="form.latlng" class="locations">
      <i class="fas fa-location-dot"></i>
    </LocationsInput>
    <hr class="vertical" />
    <SingleDropdown
      label="search date"
      name="search-dates"
      :placeholder="$t('search_bar.date_placeholder')"
      :options="availableDates"
      :show-label="false"
      v-model="form.date"
      @update:model-value="handleDateChange"
    >
      <template #default="{text}">
        <i class="fas fa-calendar"></i>
        <span>{{ text }}</span>
      </template>
    </SingleDropdown>
    <template v-if="includeType">
      <hr class="vertical" />
      <SingleDropdown
        label="search location type"
        name="search-location-type"
        :placeholder="$t('search_bar.location_placeholder')"
        :options="types"
        :show-label="false"
        :can-delect="true"
        v-model="form.location_type"
      >
        <template #default="{text}">
          <i class="fas fa-house"></i>
          <span>{{ text }}</span>
        </template>
      </SingleDropdown>
    </template>
    <template v-if="includeAmenities">
      <hr class="vertical" />
      <MultiDropdown
        label="search location amenities"
        name="search-location-amenities"
        :placeholder="$t('search_bar.amentities_placeholder')"
        :options="amenities"
        :show-label="false"
        :can-delect="true"
        v-model="form.amenities"
      />
    </template>
    <button type="submit" class="button primary">
      <span class="sr-only">{{ $t('search_bar.search') }}</span>
      <i class="fas fa-search"></i>
    </button>
  </form>
</template>

<style lang="postcss" scoped>
@import 'open-props/media';
form {
  --input-height: 5.4rem;
  --location-input-border-color: transparent;
  --location-input-focus-border-color: var(--secondary-color);
  --location-input-border-radius: var(--input-height) var(--border-radius-small) var(--border-radius-small)
    var(--input-height);
  --dropdown-button-border-color: transparent;
  --dropdown-button-focus-border-color: var(--secondary-color);
  --dropdown-button-border-radius: var(--border-radius-small);
  display: inline-flex;
  flex-direction: row;
  min-width: 100%;
  gap: 1rem;

  align-items: center;
  padding: 0.7rem;
  background-color: var(--background-color-secondary);
  border-radius: var(--border-radius-rounded);
  color: var(--text-color-secondary);
  filter: var(--drop-shadow);
  font-size: 1.8rem;
  font-weight: 500;
  @media (--md-n-below) {
    --location-input-border-radius: var(--border-radius-small);
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    border-radius: var(--border-radius-big);
    width: 100%;
  }
  & > *:not(hr, button) {
    min-width: 1px;
    flex-basis: 0px;
    flex-grow: 1;
  }
  & hr {
    height: 4rem;
    @media (--lg-n-below) {
      display: none;
    }
  }
  & button[type='submit'] {
    appearance: none;
    flex-shrink: 0;
    appearance: none;
    height: var(--input-height, 5.4rem);
    width: var(--input-height, 5.4rem);

    border-radius: var(--border-radius-rounded);
    margin-inline-start: auto;
    font-size: 1.8rem;
    padding: 0;
    @media (--md-n-below) {
      width: 100%;
      border-radius: var(--border-radius-big);
    }
  }
}
</style>
