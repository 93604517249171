import {LocationQueryValue, LocationQuery} from 'vue-router'

export const getStringValueFromQuery = ({
  value,
  defaultValue = '',
}: {
  value: LocationQueryValue | LocationQueryValue[]
  defaultValue?: string
}): string => {
  if (Array.isArray(value)) return value.at(0) ?? defaultValue
  else if (typeof value === 'string') return value
  return defaultValue
}

export const getArrayValueFromQuery = ({
  value,
  defaultValue = [],
}: {
  value: LocationQueryValue | LocationQueryValue[]
  defaultValue?: string[]
}): string[] => {
  if (Array.isArray(value)) return value.filter(Boolean)
  else if (typeof value === 'string') return [value]
  return defaultValue
}

export const getFiltersFromQuery = ({query}: {query: LocationQuery}): Required<SearchForm> => {
  const {latlng, address, date, location_type, amenities} = query
  return {
    latlng: getStringValueFromQuery({value: latlng}),
    address: getStringValueFromQuery({value: address}),
    date: getStringValueFromQuery({value: date}),
    location_type: getStringValueFromQuery({value: location_type}),
    amenities: getArrayValueFromQuery({value: amenities}),
  }
}