let geocoder: google.maps.Geocoder

export async function getLocationFromAddress(address: string) {
  geocoder = new google.maps.Geocoder()
  const data = await geocoder.geocode({address: address})
  
  return data.results.at(0)?.geometry.location.toUrlValue()
}

export async function getAddressFromLocation(location: string) {
  if (!location.includes(',')) return

  geocoder = new google.maps.Geocoder()
  const [lat,lng] = location.split(',')
  const data = await geocoder.geocode({ location: { lat: Number(lat), lng: Number(lng) } })
  
  return data.results.at(0)?.formatted_address
}
