<script lang="ts" setup>
import {onClickOutside} from '@vueuse/core'

const props = defineProps<{
  options: SelectItem[]
  name: string
  label: string
  showLabel?: boolean
  modelValue: string[]
  placeholder: string
}>()
const emit = defineEmits<{
  (e: 'update:modelValue', value: string[]): void
}>()

const target = ref(null)

onClickOutside(target, event => (isOpen.value = false))

const isOpen = ref(false)
const selectedValuesLabel = computed(() =>
  props.options
    .filter(option => props.modelValue.includes(option.value))
    .map(({label}) => label)
    .join(', ')
)
const buttonText = computed(() => ((props.modelValue?.length ?? 0) > 0 ? selectedValuesLabel.value : props.placeholder))
const toggleMenu = () => (isOpen.value = !isOpen.value)

const updateValue = (value: string) => {
  if (isValueSelected(value)) {
    emit(
      'update:modelValue',
      props.modelValue.filter(mv => mv !== value)
    )
  } else {
    emit('update:modelValue', [...props.modelValue, value])
  }
}

const isValueSelected = (value: string) => props.modelValue?.includes(value) ?? undefined
</script>

<template>
  <div class="dropdown">
    <label :for="name" v-if="showLabel" v-text="label"></label>
    <div class="dropdown-wrapper">
      <button
        type="button"
        class="picker"
        :id="name"
        role="combobox"
        aria-haspopup="true"
        :aria-expanded="isOpen"
        :title="buttonText"
        @click="toggleMenu"
      >
        <slot :text="buttonText">
          <span>{{ buttonText }}</span>
        </slot>
        <i class="fas fa-chevron-down"></i>
      </button>
      <ul role="listbox" :aria-label="label" v-if="isOpen" ref="target">
        <li v-for="option in options">
          <button
            class="button"
            type="button"
            :aria-selected="isValueSelected(option.value) ? true : undefined"
            :id="`${name}-${option.label}`"
            :value="option.value"
            @click="updateValue(option.value)"
          >
            <slot name="option" :option="option">
              <img v-if="option.icon" :src="option.icon" :alt="option.label" />
              <i v-else-if="option.fa" :class="option.fa"></i>
              {{ option.label }}
            </slot>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.dropdown {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  white-space: nowrap;
  & .dropdown-wrapper {
    position: relative;
    & button.picker {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: var(--container-gap);
      appearance: none;
      white-space: nowrap;
      height: var(--dropdown-button-height, var(--input-height, 5.4rem));
      padding: var(--dropdown-button-padding, 2.4rem);
      background-color: var(--background-color-primary);
      border-radius: var(--dropdown-button-border-radius, var(--border-radius-rounded));
      border: 1px solid var(--dropdown-button-border-color, var(--border-color));
      transition: 500ms;
      cursor: pointer;
      width: 100%;
      color: currentColor;
      &:focus {
        border-color: var(--dropdown-button-focus-border-color, var(--secondary-color));
      }
      & i {
        font-size: 1rem;
      }
      & span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    & ul {
      position: absolute;
      display: var(--dropdown-list-display, flex);
      grid-template-columns: var(--dropdown-list-grid-columns, none);
      flex-direction: column;
      top: calc(100% + 1rem);
      left: 0;
      margin: 0;
      padding: 0;
      list-style-type: none;
      filter: var(--drop-shadow);
      background-color: var(--background-color-primary);
      border: 1px solid var(--border-color);
      border-radius: var(--border-radius-small);
      z-index: var(--dropdown-list-z-index, 9999);
      overflow: auto;
      max-height: 30rem;
      & li {
        & button {
          display: flex;
          flex-wrap: nowrap;
          justify-content: flex-start;
          white-space: nowrap;
          width: 100%;
          padding: 1rem 2rem;
          color: var(--text-color-secondary);
          cursor: pointer;
          transition: 500ms;
          border-radius: var(--border-radius-small);
          border-radius: 0;
          background-color: var(--background-color-primary);
          &:focus-visible,
          &:hover {
            color: var(--text-color-primary);
            background-color: var(--background-color-secondary);
          }
          &[aria-selected] {
            color: var(--primary-color);
          }
        }
      }
    }
  }
}
</style>
