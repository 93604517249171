<script lang="ts" setup>

type OldHtmlInputElement = HTMLInputElement & {
  attachEvent: (type: string, listener: () => any) => void
}

interface props {
  includedCountries?: string[];
  address: string;
  latlng?: string;
}
interface emits {
  (e: 'update:address', address: string): void;
  (e: 'update:latlng', latlng: string): void;
}

const props = defineProps<props>()
const emit = defineEmits<emits>()

let autocomplete: google.maps.places.Autocomplete

const input = ref<HTMLInputElement | null>(null)

function initAutocomplete() {
  if (!input.value) return
  autocomplete = new google.maps.places.Autocomplete(input.value, {
    componentRestrictions: { country: props.includedCountries ?? [] },
    // types: ["address"],
  });
  autocomplete.addListener("place_changed", fillInAddress);
}

function fillInAddress() {
  emit('update:address', autocomplete.getPlace().formatted_address ?? '')
  emit('update:latlng', autocomplete.getPlace().geometry?.location?.toUrlValue() ?? '')
}

function selectFirstOnEnter(event: KeyboardEvent) {  // store the original event binding function
  event.preventDefault()
  downArrowSimulator(input.value)
}

function downArrowSimulator(input: HTMLInputElement | null): void {
  if (!input) {
    throw new Error(
      `The input for downArrowSimulator should be defined, currently: ${input}`
    )
  }

  const _addEventListener = oldHtmlInputElementGuard(input)
    ? input.attachEvent
    : input.addEventListener

  /**
   * Add event listener wrapper that will replace to default addEventListener or attachEvent function
   *
   * @param  {string} type the event type
   * @param  {Function} listener function should be executed when the event is fired
   * @returns {void}
   */
  function addEventListenerWrapper(
    type: string,
    listener: (...args: any[]) => any
  ): void {
    // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected,
    // and then trigger the original listener.
    if (type === 'keydown') {
      const origListener = listener;
      // eslint-disable-next-line no-param-reassign -- Is old style this should be analyzed
      listener = (event: KeyboardEvent) => {
        const suggestionSelected =
          document.getElementsByClassName('pac-item-selected').length > 0
        if (
          event.key === 'Enter' &&
          event.code === 'Enter' &&
          !suggestionSelected
        ) {
          const simulatedArrowDownKeyDownEvent = new KeyboardEvent('keydown', {
            key: 'ArrowDown',
            code: 'ArrowDown',
            keyCode: 40,
            which: 40,
          });
          origListener.apply(input, [simulatedArrowDownKeyDownEvent]);
        }

        origListener.apply(input, [event])
      };
    }

    _addEventListener.apply(input, [type, listener])
  }

  input.addEventListener = addEventListenerWrapper

  if (oldHtmlInputElementGuard(input)) {
    input.attachEvent = addEventListenerWrapper
  }
}

function oldHtmlInputElementGuard(
  input: HTMLInputElement | OldHtmlInputElement
): input is OldHtmlInputElement {
  return (input as OldHtmlInputElement).attachEvent !== undefined
}

onMounted(async() => {
  initAutocomplete()
  if (input.value) downArrowSimulator(input.value)
})
</script>

<template>
  <div class="wrapper">
    <slot></slot>
    <input :value="address" ref="input" :placeholder="$t('search_bar.address_placeholder')" @keydown.enter.stop.prevent="selectFirstOnEnter">
  </div>
</template>

<style lang="postcss" scoped>
.wrapper {
  display: flex;
  align-items: center;
  gap: 2rem;
  padding-inline: 2.4rem;
  border-radius: var(--location-input-border-radius, var(--border-radius-rounded));
  border: 1px solid var(--location-input-border-color, var(--border-color));
  &:focus-within {
    border-color: var(--location-input-focus-border-color, var(--secondary-color));
  }

  & input {
    appearance: none;
    border: 0;
    height: 100%;
    font-size: inherit;
    outline-style: solid;
    outline: transparent;
    color: currentColor;
    background-color: transparent;
    flex-basis: 0;
    font-weight: 400;
    height: var(--locations-input-height, var(--input-height, 5.4rem));
    padding: 0;
    &::placeholder {
      opacity: 1;
      color: currentColor;
      font-size: inherit;
      font-weight: 400;
    }
  }
}
</style>