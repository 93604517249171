<script lang="ts" setup>
import {onClickOutside} from '@vueuse/core'
const props = defineProps<{
  options: SelectItem[]
  name: string
  label: string
  showLabel?: boolean
  modelValue: string
  placeholder: string
  canDeselect?: boolean
  error?: string
}>()
const emit = defineEmits<{
  (e: 'update:modelValue', value: string | null): void
  (e: 'blur'): void
}>()

const target = ref(null)

onClickOutside(target, event => (isOpen.value = false))

const isOpen = ref(false)
const selectedValue = computed(
  () => props.options.find(option => option.value === props.modelValue)
)
const buttonText = computed(() => selectedValue.value?.label ?? props.placeholder)
const toggleMenu = () => (isOpen.value = !isOpen.value)

const updateValue = (value: string) => {
  emit('blur')
  emit('update:modelValue', props.canDeselect ? (props.modelValue === value ? '' : value) : value)
  isOpen.value = false
}

const isValueDisabled = (value: string) => (props.canDeselect ? undefined : isValueSelected(value) ?? undefined)
const isValueSelected = (value: string) => (props.modelValue?.includes(value) ? true : undefined)
</script>

<template>
  <div class="dropdown" ref="target">
    <label :for="name" v-if="showLabel" v-text="label"></label>
    <div class="dropdown-wrapper">
      <button
        type="button"
        class="picker"
        :id="name"
        role="combobox"
        aria-haspopup="true"
        :aria-expanded="isOpen"
        :title="buttonText"
        @click="toggleMenu"
      >
        <slot :text="buttonText">
          <img v-if="selectedValue && selectedValue.icon" :src="selectedValue.icon" :alt="selectedValue.label">
          <i v-else-if="selectedValue && selectedValue.fa" :class="selectedValue.fa"></i>
          <span>{{ buttonText }}</span>
        </slot>
        <i class="fas fa-chevron-down"></i>
      </button>
      <ul role="listbox" :aria-label="label" v-if="isOpen" >
        <li v-for="option in options">
          <button
            class="button"
            :class="{ disabled: option.disabled }"
            type="button"
            :aria-selected="isValueSelected(option.value) ? true : undefined"
            :id="`${name}-${option.label}`"
            :value="option.value"
            :disabled="option.disabled"
            @click="updateValue(option.value)"
          >
            <slot name="option" :option="option">
              <img v-if="option.icon" :src="option.icon" :alt="option.label" />
              <i v-else-if="option.fa" :class="option.fa"></i>
              {{ option.label }}
            </slot>
          </button>
        </li>
      </ul>
    </div>
    <small v-if="error" class="error" v-text="error"></small>
  </div>
</template>

<style lang="postcss" scoped>
.dropdown {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  & .dropdown-wrapper {
    position: relative;
    & button.picker {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 2rem;
      appearance: none;
      white-space: nowrap;
      cursor: pointer;
      height: var(--dropdown-button-height, var(--input-height, 5.4rem));
      padding: var(--dropdown-button-padding, 2.4rem);
      background-color: var(--background-color-primary);
      border-radius: var(--dropdown-button-border-radius, var(--border-radius-rounded));
      border: 1px solid var(--dropdown-button-border-color, var(--border-color));
      transition: 500ms;
      width: 100%;
      color: currentColor;
      &:focus {
        border-color: var(--dropdown-button-focus-border-color, var(--secondary-color));
      }
      & i {
        font-size: 1rem;
      }
      & .fa-chevron-down {
        margin-left: auto;
      }
      & :slotted(span) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      & span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    & ul {
      position: absolute;
      display: var(--dropdown-list-display, flex);
      grid-template-columns: var(--dropdown-list-grid-columns, none);
      flex-direction: column;
      top: calc(100%);
      left: 0;
      margin: 0;
      padding: 0;
      list-style-type: none;
      filter: var(--drop-shadow);
      background-color: var(--background-color-primary);
      border: 1px solid var(--border-color);
      border-radius: var(--border-radius-small);
      z-index: var(--dropdown-list-z-index, 9999);
      overflow: auto;
      max-height: 24rem;
      width: 100%;
      & li {
        width: 100%;
        & button {
          display: flex;
          flex-wrap: nowrap;
          justify-content: flex-start;
          white-space: normal;
          text-align: left;
          width: 100%;
          padding: 1rem 2rem;
          gap: 0.8rem;
          color: var(--text-color-secondary);
          cursor: pointer;
          transition: 500ms;
          border-radius: var(--border-radius-small);
          border-radius: 0;
          background-color: var(--background-color-primary);
          &:focus-visible,
          &:hover {
            color: var(--text-color-primary);
            background-color: var(--background-color-secondary);
          }
          &[aria-selected] {
            cursor: default;
            color: var(--primary-color);
          }
          &.disabled {
            cursor: not-allowed;
            color: gray;
          }
        }
      }
    }
  }
}
</style>
